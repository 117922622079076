import { BASE_URL } from "../api.constants";
import {axiosApiInstance,axiosApiInst} from "@/plugins/axios";
// eslint-disable-next-line no-unused-vars
import { FarmerGroup } from ".";
/**
 * @param {string} group_id
 * @returns {Promise<FarmerGroup>}
 */
async function find_group(group_id){
    const res = await axiosApiInstance({url:BASE_URL+"/"+group_id});
    return res.data
}
/**
 * 
 * @param {FarmerGroup} group 
 * @returns {Promise<FarmerGroup>}
 */
async function edit_group(group){
    const res = await axiosApiInst({
        url:BASE_URL+"/"+group.id,
        method:"patch",
        data:group
    })
    return res.data
}
/**
 * @param {string} group_id
 * @returns {Promise}
 */
async function delete_group(group_id){
    const res = await axiosApiInst({
        url:BASE_URL+"/"+group_id,
        method:"delete"
    });
    return res.data;
}
export {find_group,edit_group,delete_group}