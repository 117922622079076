import {axiosApiInstance} from "@/plugins/axios"
import AddFarmer from '../../../../components/add_farmer/AddFarmer.vue';
import Vue from "vue";
const NewFarmer = Vue.mixin({
    components:{AddFarmer},
    data(){
        return {
            crops:[]
        }
    },
    methods:{
        setCropsToSelect() {
            const baseUrl = process.env.VUE_APP_BACKEND
      
            axiosApiInstance
              .get(baseUrl + `/api/v3/farmers/crops`)
              .then((result) => {
                if (result.data.success) {
                  result.data.data.map(each => {
                    this.crops.push(each.crop);
                  });
                }
              })
              .catch(error => {
                this.error = error;
              })
          },
    }
});
export {NewFarmer}