<template>
    <b-modal id="edit-group" @shown="init" title="Edit Farmer Group" hide-footer>
        <b-overlay :show="loading" spinner-variant="primary">
            <group-form :group.sync="group" @save-group="editGroup"></group-form>
        </b-overlay>
    </b-modal>
</template>
<script>
import GroupForm from './components/GroupForm.vue'
import { FarmerGroup } from './farmer_group'
import {edit_group} from "./farmer_group/api.calls"
export default {
    components:{GroupForm},
    props:["fgroup"],
    data(){
        return {
            group:new FarmerGroup,
            loading:false
        }
    },

    methods:{
        init(){
            this.group = this.fgroup;
        },
        editGroup(){
            this.loading = true;
            edit_group(this.group).then(()=>{
                this.loading = false;
                this.$bvModal.hide("edit-group");
            })
        }
    }
}
</script>