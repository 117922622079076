import { FARMERS_URL } from "./api.constants";
import { axiosApiInstance } from '@/plugins/axios';
import * as axUtils from "../../../dashboard/axios"

/**
 * 
 * @param {number} project_id 
 * @param {{group_id:string,limit:string,offset:string}} params 
 * @param {boolean} cache
 * @returns {Promise<import("axios").AxiosResponse>}
 */

 async function project_farmers(project_id,params,cache){
    const ax = cache?axUtils.base_axios():axUtils.no_cache();
    return await ax.get(FARMERS_URL+"/"+project_id,{
        params
    });
}

/**
 * 
 * @param {number} project_id 
 * @param {{group_id:string,limit:string,offset:string}} params
 * @returns {Promise<Array<import("./api.constants").Farmer>>}
 */
async function farmers_per_project(project_id,params={}){
    const result = await project_farmers(project_id,params,false);
    return result.data
}

/**
 * 
 * @param {string} farmer_id 
 * @param {string} group_id 
 * 
 */
async function ungroup(farmer_id,group_id){
    const res = await axiosApiInstance({
        url:FARMERS_URL+"/"+farmer_id+"/:ungroup",
        params:{group_id},
        method:"post"
    })
    return res.data
}
export {farmers_per_project,FARMERS_URL,ungroup,project_farmers}