<template>
  <main>
    <div class="d-flex my-2">
      <h4 class="align-self-end">
        <b-link
          @click="
            () => {
              $router.go(-1);
            }
          "
          >FARMER GROUPS </b-link
        ><small><b-icon-chevron-right></b-icon-chevron-right></small>
        <small>{{ group.name }}</small>
      </h4>
      <b-button
        variant="danger"
        class="ml-auto"
        size="md"
        squared
        @click="deleteGroup"
      >
        Delete Farmer Group
      </b-button>
      &nbsp;
      <b-button variant="outline-primary" v-b-modal.edit-group>
        <b-icon-plus></b-icon-plus>
        &nbsp; Edit details
      </b-button>
      &nbsp;
      <b-button
        variant="primary"
        v-b-modal.add-farmer
        :disabled="!can('modify', 'suppliers')"
      >
        <b-icon-plus></b-icon-plus>
        &nbsp; New Farmer
      </b-button>
    </div>
    <b-navbar>
      <b-navbar-brand>{{ group.name }}</b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <button class="btn-opt primary">
          <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
          Refresh
        </button>
        <button class="btn-opt primary">
          <b-icon-printer></b-icon-printer> Print
        </button>
        <download-excel
          class="btn btn-opt primary"
          :data="items"
          v-analytics:click="['export_farmers', { element: 'Suppliers' }]"
          :fields="csv_fields()"
          type="csv"
        >
          <b-icon-book></b-icon-book> Export
        </download-excel>
      </b-navbar-nav>
    </b-navbar>
    <hr class="py-0 mt-0 mb-4" style="background-color: #e2e2e2" />
    <div class="d-flex">
      <b-col cols="8">
        <b-form inline class="mb-2">
          <b-form-input
            placeholder="Search Name: farmer/agent"
            type="search"
            class="search w-50"
            v-model="searchfield"
          ></b-form-input>
          &nbsp;
          <b-form-select
            v-model="value_chain"
            :options="vchains"
            @change="getFarmersGeneric"
          >
          </b-form-select>
          &nbsp;
          <b-form-select
            v-model="district"
            :options="gdistricts"
            @change="getFarmersGeneric"
          >
          </b-form-select>
        </b-form>
        <b-table
          head-variant="info"
          :filter="searchfield"
          :filter-included-fields="['farmer_name', 'va_name']"
          :items="items"
          :fields="fields"
          :busy="isBusy"
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #empty>
            <div class="text-center">No farmers added yet...</div>
          </template>
          <template #cell(farmer_id)="data">
            <b-link @click="deleteFarmer(data.item.farmer_id)">
              <b-icon-trash variant="danger"></b-icon-trash>
            </b-link>
          </template>
        </b-table>
      </b-col>
      <b-col cols="4" class="pb-5">
        <div class="mb-4 mt-4">
          <h6 class="text-muted mb-2">Contact Person</h6>
          <a :href="'tel:' + group.contact.phone_no">{{
            group.contact.name
          }}</a>
        </div>
        <div class="d-flex">
          <b-col cols="5" class="px-0 border-right">
            <h6 class="text-muted mb-2">Total farmers</h6>
            <b>{{ group.farmer_count }}</b>
          </b-col>
          <b-col cols="6" class="pl-5">
            <h6 class="text-muted mb-2">Acres</h6>
            <b>{{ group.acres }}</b>
          </b-col>
        </div>
      </b-col>
    </div>
    <edit-group :fgroup="group"></edit-group>
    <add-farmer :crops-data="crops"></add-farmer>
  </main>
</template>
<script>
import { farmers_per_project, ungroup } from "../../farmers/api.calls";
import { delete_group, find_group } from "./api.calls";
import { FarmerGroup } from ".";
import EditGroup from "../EditGroup.vue";
import { NewFarmer } from "../../farmers/mixins";
import { can } from "../../../../auth/utils";
import { fields as csv_fields } from "../../farmers/ui.utils";
export default {
  components: { EditGroup },
  mixins: [NewFarmer],
  data() {
    const fields = [
      { key: "farmer_name", label: "Name" },
      // { key: "farmer_phone_number", label: "Contact" },
      { key: "garden_acreage_mapped_gps", label: "Size" },
      { key: "value_chain", label: "Produce" },
      { key: "va_name", label: "Agent" },
      { key: "farmer_id", label: "" },
    ];
    return {
      fields,
      items: [],
      searchfield: null,
      group: new FarmerGroup(),
      value_chain: null,
      district: null,
      gdistricts: [{ text: "All districts", value: null }],
      vchains: [
        {
          text: "All Produce",
          value: null,
        },
      ],
      csv_fields,
      isBusy:false
    };
  },
  computed: {
    group_id() {
      return this.$route.params.group_id;
    },
    projectId() {
      return this.$store.state.project_id;
    },
  },
  mounted() {
    this.getFarmers();
    find_group(this.group_id).then((data) => {
      this.group = data;
    });
    this.setCropsToSelect();
  },
  methods: {
    can,
    getFarmers() {
      this.isBusy=true;
      farmers_per_project(this.projectId, { group_id: this.group_id }).then(
        (data) => {
          this.isBusy = false;
          this.items = data;
          const vchains = new Set(data.map((el) => el.value_chain));
          const chains = Array.from(vchains).map((el) => ({
            text: el,
            value: el,
          }));
          let fdistricts = new Set(data.map((el) => el.farmer_district));
          fdistricts = Array.from(fdistricts).map((el) => ({
            text: el,
            value: el,
          }));
          this.gdistricts = [
            { text: "All districts", value: null },
            ...fdistricts,
          ];
          this.vchains = [{ text: "All Produce", value: null }, ...chains];
        }
      );
    },
    getFarmersGeneric() {
      farmers_per_project(this.projectId, {
        group_id: this.group_id,
        value_chain: this.value_chain,
        district: this.district,
      }).then((data) => {
        this.items = data;
      });
    },
    deleteGroup() {
      this.$dialog
        .confirm("Are you sure you want to permanently delete this group?")
        .then(() => {
          delete_group(this.group.id).then(() => {
            this.$router.go(-1);
          });
        });
    },
    deleteFarmer(farmer_id) {
      this.$dialog
        .confirm("Are you sure you want to delete farmer from group?")
        .then(() => {
          ungroup(farmer_id, this.group.id).then(() => {
            this.getFarmers();
            this.$bvToast.toast("Farmer has been successfully removed from group", {
              title: "Operation Successful",
              variant: "success",
              solid: true
            });
          });
        });
    },
  },
};
</script>