const FARMERS_URL = process.env.VUE_APP_BACKEND + process.env.VUE_APP_FARMER_PFX;
/**
 * @typedef Farmer
 * @property {string} farmer_name
 * @property {string} farmer_phone_number
 * @property {string} value_chain
 * @property {string} farmer_group
 * @property {string} time
 * @property {string} farmer_district
 * @property {string} has_meta
 */
export {FARMERS_URL}