import { axiosApiInstance } from "@/plugins/axios";
function add_farmer() {
    const data = this.form;
    axiosApiInstance({
        url: process.env.VUE_APP_BACKEND + "/farmer_add_url",
        method: 'post',
        data: JSON.stringify(data)
    }).then(res=>{
        
       console.log(res.status);
    });
}
export {add_farmer}