<template>
  <!-- Add farmer Modal -->
  <b-modal id="add-farmer" title="New Farmer" hide-footer>
    <div class="container-fluid">
      <b-form
        @submit.prevent="addFarmersPerProject"
        @reset.prevent="onReset"
        v-if="show"
      >
        <b-form-group label-for="farmer-name">
          <template v-slot:label>
            Name <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="farmer-name"
            v-model.trim="$v.addFarmerForm.farmer_name.$model"
            type="text"
            required
          ></b-form-input>
          <span
            v-show="!$v.addFarmerForm.farmer_name.minLength"
            class="text-danger"
          >
            <small
              >Name must have at least
              {{ $v.addFarmerForm.farmer_name.$params.minLength.min }}
              letters.</small
            >
          </span>
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group id="input-group-2" label-for="input-2">
              <template v-slot:label>
                Date of birth <span class="text-danger">*</span>
              </template>
              <b-form-datepicker
                v-model="addFarmerForm.farmer_dob"
                type="text"
                required
              >
              </b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="input-group-3" label-for="input-3">
              <template v-slot:label>
                Gender <span class="text-danger">*</span>
              </template>
              <b-form-select
                id="input-3"
                v-model="addFarmerForm.farmer_gender"
                :options="gender"
                required
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group id="input-group-4" label-for="input-4">
          <template v-slot:label>
            Phone Number <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="input-4"
            v-model="addFarmerForm.farmer_phone_number"
            type="tel"
            placeholder="e.g 0702000888"
            pattern="^07[0-9]{8}"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-4"
          label="National ID Number"
          label-for="input-9"
        >
          <b-form-input
            id="input-9"
            v-model="addFarmerForm.public_id_number"
          ></b-form-input>
        </b-form-group>
        <b-row>
          <b-col cols="6">
            <b-form-group id="input-group-7" label="Region" label-for="input-7">
              <b-form-select
                id="input-7"
                v-model="addFarmerForm.farmer_region"
                :options="regions"
                required
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <vue-districts
              :districts="districts"
              v-model="addFarmerForm.farmer_district"
            ></vue-districts>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="5">
            <b-form-group id="input-group-6" label-for="input-6">
              <template v-slot:label>
                Village <span class="text-danger">*</span>
              </template>
              <b-form-input
                id="input-6"
                type="text"
                v-model="addFarmerForm.farmer_village"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="7">
            <b-form-group
              id="input-group-va"
              label-for="va"
            >
              <template v-slot:label>
                Village Agent <span class="text-danger">*</span>
              </template>
              <b-form-select
                id="va"
                v-model="addFarmerForm.vaId"
                :options="villageAgents"
                @change="check_if_add"
                required
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button v-show="showAddAgent" @click="routePage"
          >Click this link to add an agent</b-button
        >
        <b-form-group id="input-group-8" label="Crop" label-for="input-8">
          <b-form-input
            list="crop-list"
            v-model="addFarmerForm.value_chain"
            required
            placeholder="Type a crop..."
          ></b-form-input>
          <datalist id="crop-list">
            <option
              :value="crop"
              v-for="crop in cropsData"
              :key="crop"
            ></option>
          </datalist>
        </b-form-group>
        <b-form-group
          label="Farmer Group (Optional)"
          id="input-group-9"
          label-for="input-9"
        >
          <b-form-select
            v-model="addFarmerForm.group"
            :options="groups"
          ></b-form-select>
        </b-form-group>
        <div class="float-right">
          <b-button
            type="reset"
            v-analytics:click="['cancel_add_farmer', { element: 'Suppliers' }]"
            variant="secondary"
            >Cancel</b-button
          >
          <b-button
            type="submit"
            variant="primary"
            class="ml-2"
            v-analytics:click="['save_new_farmer', { element: 'Suppliers' }]"
          >
            <b-spinner v-if="isAdding" small label="Small Spinner"></b-spinner>
            Submit
          </b-button>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>
<script>
import { axiosApiInstance } from "@/plugins/axios";
import { add_farmer } from "./actions.js";
import VueDistricts from "../VueDistricts.vue";
import { list_groups } from "../../modules/suppliers/subroutes/farmer_groups/api.calls";
import { format_options, farmerValidations } from "./ui.utils";

export default {
  components: { VueDistricts },
  data() {
    const groups = [{ value: null, text: "Select Farmer Group (Optional)" }];
    return {
      addFarmerForm: {
        farmer_name: "",
        farmer_dob: "",
        farmer_gender: null,
        farmer_phone_number: "",
        farmer_district: null,
        farmer_village: "",
        farmer_region: "",
        value_chain: "",
        public_id_number: "",
        vaId: "",
        group: null,
      },
      gender: [{ text: "Select Gender", value: null }, "Male", "Female"],
      regions: [
        { text: "Select Region", value: null },
        "East",
        "West",
        "North",
        "Central",
      ],
      districts: [],
      villageAgents: [
        { text: "Select Agent", value: null },
        { text: "Add Agent", value: "add_agent" },
      ],
      show: true,
      isAdding: false,
      showAddAgent: false,
      groups,
    };
  },
  validations: farmerValidations,
  props: {
    cropsData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  mounted: function () {
    this.setAgentsToSelect();
    this.setDistrictsToSelect();
    list_groups().then((data) => {
      this.groups = data.map(format_options);
    });
  },

  computed: {
    projectId() {
      return this.$store.state.project_id;
    },
  },

  methods: {
    onReset() {
      this.addFarmerForm.farmer_name = "";
      this.addFarmerForm.farmer_dob = "";
      this.addFarmerForm.farmer_gender = null;
      this.addFarmerForm.farmer_phone_number = "";
      this.addFarmerForm.farmer_district = null;
      this.addFarmerForm.farmer_village = "";
      this.addFarmerForm.farmer_region = "";
      this.addFarmerForm.value_chain = "";
      this.addFarmerForm.public_id_number = "";
      this.addFarmerForm.vaId = "";
      this.$bvModal.hide("add-farmer");
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    /**
     * @param {string} val
     */
    check_if_add(val) {
      if (val == "add_agent") {
        this.routePage();
      }
    },
    routePage() {
      this.$router.push({ path: "/suppliers/agents" });
    },
    addFarmersPerProject() {
      this.isAdding = true;
      var self = this;
      const baseUrl = process.env.VUE_APP_BACKEND;

      axiosApiInstance
        .post(
          baseUrl + `/api/v3/farmers/Uganda/${this.projectId}`,
          this.addFarmerForm
        )
        .then((result) => {
          if (result.data.success) {
            this.message = result.data.message;
            this.onReset();
            this.isAdding=false;
            this.$emit("AddFarmerSuccess");
          } else if (!result.data.success) {
            this.isAdding = false;
            this.error = result.data.message;
            // alert(this.error);
            self.$vToastify.error("Something went wrong please try again");
          }
        })
        .catch((error) => {
          if (error.response.status == 409) {
            this.addExistingFarmer(baseUrl, error.response.data);
          } else {
            this.isAdding = false;
            this.error = error;
            // alert(this.error);
            self.$vToastify.error("Something went wrong please try again");
          }
        });
    },

    addExistingFarmer(baseUrl, data) {
      try {
        const msg = `${this.addFarmerForm.farmer_name} is already registered in another project.
            Do you wish to have this farmer registered in this project too?
            `;
        //@const {farmer_id:string}
        const farmer = data.data;
        if (farmer.projects.includes(parseInt(this.projectId))) {
          throw `${farmer.farmer_name} is already registered in project.`;
        }
        this.$dialog.confirm(msg).then(() => {
          axiosApiInstance
            .patch(
              baseUrl +
                `/api/v3/farmers/${farmer.farmer_id}/:add_existing/${this.projectId}`
            )
            .then((resp) => {
              this.message = resp.data.message;
              this.onReset();
              this.$emit("AddFarmerSuccess", this.isAdding);
            });
        });
      } catch (e) {
        console.error(e);
        this.isAdding = false;
        this.error = e;
        // alert(this.error);
        this.$bvToast.toast(e, {
          variant: "danger",
          title: "Operation Unsuccessful",
        });
      }
    },
    setAgentsToSelect() {
      const baseUrl = process.env.VUE_APP_BACKEND;

      axiosApiInstance
        .get(baseUrl + `/api/v3/village-agents/${this.projectId}`)
        .then((result) => {
          if (result.data.success) {
            if (!result.data.data.length) {
              this.showAddAgent = true;
            } else {
              result.data.data.map((each) => {
                this.villageAgents.push({
                  text: each.va_name,
                  value: each.vaId,
                });
              });
              this.showAddAgent = false;
            }
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },

    setDistrictsToSelect() {
      const baseUrl = process.env.VUE_APP_BACKEND;

      axiosApiInstance
        .get(baseUrl + `/api/v3/farmers/districts`)
        .then((result) => {
          if (result.data.success) {
            result.data.data.map((each) => {
              this.districts.push(each.district);
            });
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },

    add_farmer,
  },
};
</script>

<style>
.height-20 {
  max-height: 20vh;
}
</style>
