const fields = ()=>({
    "Assets Held":"assets_held",
    "Farmer Country":"farmer_country",
    "Farmer District":"farmer_district",
    "Date Of Birth":"farmer_dob",
    "Email":"farmer_email",
    "Gender":"farmer_gender",
    "Farmer ID":"farmer_id",
    "Home (GPS) Acc.":"farmer_location_farmer_home_gps_Accuracy",
    "Home (GPS) Alt.":"farmer_location_farmer_home_gps_Altitude",
    "Home (GPS) Lat.":"farmer_location_farmer_home_gps_Latitude",
    "Farmer Name":"farmer_name",
    "Parish":"farmer_parish",
    "Phone No.":"farmer_phone_number",
    "Region":"farmer_region",
    "Subcounty":"farmer_subcounty",
    "Village":"farmer_village",
    "Garden acreage mapped":"garden_acreage_mapped_gps",
    "Garden acreage not mapped":"garden_acreage_not_mapped_gps",
    "Garden Mapped":"garden_mapped",
    "ma_id":"ma_id",
    "No. of households":"number_of_households",
    "Partner ID":"partner_id",
    "public id no.":"public_id_number",
    "public id type":"public_id_type",
    "time":"time",
    "VA ID":"vaId",
    "VA Name":"va_name",
    "Value Chain":"value_chain",
    "Value Chain Variety":"value_chain_variety",
    "Group":{
        field:"farmer_group",
        callback:(value)=>{
            return value.name;

        }
    }
})
const STATUS={
    EMPTY:"No farmers added yet.",
    MISSING:"No farmers marching criteria"
}
export {fields,STATUS}